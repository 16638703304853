import React, { useEffect, useState } from 'react';
import { Alert, Button, Checkbox, CheckboxGroup, Select, Tag } from '@stonelog/stonelog-design-system';
import { useSelector, useDispatch } from "react-redux";
import { manageSelectedRelocatePins, pinsSelector, resetSelectedRelocatePins, setCenter, setZoom } from '../../../../../../../../../features/senninha/pinsSlice';
import { angelsSelector } from '../../../../../../../../../features/senninha/angelsSlice';
import { hubsSelector } from '../../../../../../../../../features/senninha/hubsSlice';
import { routesManagementSelector } from '../../../../../../../../../features/senninha/routesManagementSlice';

import * as S from './styles';


const RelocateRoutes = ({
    setNewWorkFlow,
    checkedList,
    setCheckedList,
    setRoutes,
    isDeallocate = undefined
}) => {    
    const dispatch = useDispatch()

    const {
        selectedHubInfos
    } = useSelector(hubsSelector);

    const {
        selectedRelocatePins
    } = useSelector(pinsSelector);

    const {
        angels,
        status
    } = useSelector(angelsSelector);

    const {
        unassociatedPinAnyData,
    } = useSelector(routesManagementSelector);

    const [selectedAngel, setSelectedAngel] = useState()
    const [checkboxOptions, setCheckboxOptions] = useState([])

    const isBlocked = !!unassociatedPinAnyData.unassociatedPin

    const checkAll = checkboxOptions.length === checkedList.length && checkedList.length
    const indeterminate = checkedList.length > 0 && checkedList.length < checkboxOptions.length
    
    const getTotalOss = () => selectedRelocatePins.reduce((sum, item) => sum += item.oss.length, 0)

    const getAngelsOptions = () => angels.map(({ nome }) => ({
        value: nome,
        label: nome
    }))

    const onChange = (list) => {
        const notCheckedPinsIds = checkboxOptions.filter((item) => checkedList.includes(item))
        notCheckedPinsIds.forEach((pinId) => {
            const pinData = unassociatedPinAnyData.deliveriesPins2BeAssociated.find((item) => item.id === pinId)
            const isSelected = selectedRelocatePins.find((item) => item.id === pinData.id)
            if (isSelected)
                dispatch(manageSelectedRelocatePins(pinData))
        })

        list.forEach((pinId) => {
            dispatch(manageSelectedRelocatePins(unassociatedPinAnyData.deliveriesPins2BeAssociated.find((item) => item.id === pinId)))
        })

        setCheckedList(list);
        // console.log(notCheckedPinsIds, list)
    };

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? checkboxOptions : []);
        unassociatedPinAnyData.deliveriesPins2BeAssociated.forEach((item) => {
            if (!indeterminate || !checkedList.includes(item.id)) {
                dispatch(manageSelectedRelocatePins(item));
            }
        });        
    };

    const handleSeeOnMapClick = (lat, lng) => {
        dispatch(setCenter({
            center: {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            }
        }))
        dispatch(setZoom(20))
    }

    const managePinRemoval = (pin) => {
        dispatch(manageSelectedRelocatePins(pin))
        setCheckedList((p) => p.filter((item) => item.id === pin.id))
    }

    const handleSaveClick = () => {
        setRoutes((p) => [...p, {
            type: isDeallocate ? 'deallocation' : 'relocation',
            angel: angels?.find(({ nome }) => nome === selectedAngel),
            pins: selectedRelocatePins,
            ossAmount: getTotalOss(),
            polo: selectedHubInfos.children,
        }])
        setNewWorkFlow(false)
    }

    useEffect(() => {
        if (unassociatedPinAnyData.deliveriesPins2BeAssociated)
            setCheckboxOptions(unassociatedPinAnyData.deliveriesPins2BeAssociated.map(({ id }) => id))
    }, [unassociatedPinAnyData.deliveriesPins2BeAssociated])

    return (
        <S.RealocateContainer>
            {!isDeallocate && 
                <div>
                    <Select
                        label='Realocar para'
                        placeholder='Selecione um Angel'
                        options={getAngelsOptions()}
                        value={selectedAngel}
                        onChange={(v) => setSelectedAngel(v)}
                        loading={status === 'pending'}
                        size="small"
                        showSearch
                    />
                </div>
            }
            {/* <div> // --> V2
                <Button
                    size='small'
                    solid
                >
                    <GingaIcon
                        name="moon"
                        color="#303742"
                        size="16px"
                    />
                    Desenhar área de seleção
                </Button>
            </div> */}
            <S.RealocateInfos>
                <p>
                    Total de pins selecionados
                </p>
                <span>
                    {selectedRelocatePins?.length}
                </span>
            </S.RealocateInfos>
            <S.RealocateInfos>
                <p>
                    Total de OS
                </p>
                <span>
                    {getTotalOss()}
                </span>
            </S.RealocateInfos>
            <S.RealocateTags>
            {selectedRelocatePins.map(
                (item) => item.oss.map(({ id_os }, i) => 
                    (item.tipo !== 'any' && i === 0 || item.tipo === 'any') &&
                        <S.TagArea
                            key={i}
                            isBlocked={isBlocked}
                        >
                            <Tag
                                text={item.tipo === 'any' ? id_os?.toString() : item.id_atendimento?.toString()}
                                onClose={() => !isBlocked && managePinRemoval(item)}
                            />
                        </S.TagArea>
                )
            )}
            </S.RealocateTags>

            {unassociatedPinAnyData?.unassociatedPin &&
                <>
                    <Alert
                        type="warning"
                        description={
                            `O pin %b%${unassociatedPinAnyData?.unassociatedPin?.id_atendimento}%b% de %b%${
                                unassociatedPinAnyData?.unassociatedPin?.servico?.toLowerCase()
                            }%b% que você selecionou possui %b%${
                                unassociatedPinAnyData?.deliveriesPins2BeAssociated?.length
                            }%b% pins de %b%entrega%b% associados a ele. Selecione as entregas que deseja realocar (Selecione no mín. 1).`
                        }
                    />
                    <S.DeliveriesArea>
                        <S.DeliveryItem>
                            <Checkbox
                                label={`Selecionar todos (${checkedList?.length})`}
                                indeterminate={indeterminate}
                                onChange={onCheckAllChange}
                                checked={checkAll}
                                size={24}
                            />
                        </S.DeliveryItem>
                        <CheckboxGroup onChange={onChange} value={checkedList} style={{ width: '100%' }}>
                            {unassociatedPinAnyData.deliveriesPins2BeAssociated && unassociatedPinAnyData.deliveriesPins2BeAssociated.map((item, i) => (
                                <S.DeliveryItem key={i*0.1}>
                                        <Checkbox
                                            label={item.angel}
                                            supportLabel={`${item?.id_atendimento} | Coleta/${true ? 'Entrega' : 'Polo'}`}
                                            size={24}
                                            value={item.id}
                                        />
                                        {item.outro_polo ? 
                                            <div style={{ width: 135 }}>
                                                <Tag
                                                text="Entrega de outro polo"
                                                type="info"
                                                />
                                            </div>
                                            :
                                            <span onClick={() => handleSeeOnMapClick(item.lat, item.lng)}>
                                                Ver no mapa
                                            </span>
                                        }
                                    </S.DeliveryItem>
                            ))}
                        </CheckboxGroup>
                    </S.DeliveriesArea>
                </>
            }

            <S.Footer>
                <Button
                    size='small'
                    onClick={() => {
                        setNewWorkFlow(false)
                        dispatch(resetSelectedRelocatePins())
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    size='small'
                    type='primary'
                    disabled={!selectedRelocatePins?.length || (!selectedAngel && !isDeallocate)}
                    onClick={handleSaveClick}
                >
                    Salvar
                </Button>
            </S.Footer>
        </S.RealocateContainer>
    )
};

export default RelocateRoutes;
