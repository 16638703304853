import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { SumarioDeResultados } from "./SumarioDeResultados";
import moment from "moment";
import LoadingSpinner from "@components/LoadingSpinner";
import GreenAngels from "./GreenAngels";
import Distritais from "./Distritais";
import GuardianAngels from "./GuardianAngels";
import HostAngels from "./HostAngels";
import SupplierAngels from "./SupplierAngels";
import LogisticService from "@LogisticService";
import PainelDeBusca from "./PainelDeBusca";
import Page from "@components/Page";
import Container from "@components/Container";
import Box from "@components/Box";
import { AtualizacaoText } from "../styles";
import { PageLayout } from "../../../../Layout/PageLayout";

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const formatarNomePilar = (str) =>
  capitalize(str).replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace("-", " ").replace("_", " ")
  );

const Dashboard = (props) => {
  const location = useLocation();
  const [nome, setNome] = useState(location.state.name);
  const [mesReferencia, setMesReferencia] = useState(location.state.month);
  const [anoReferencia, setAnoReferencia] = useState(location.state.year);
  const [outrosNomesDisponiveis, setOutrosNomesDisponiveis] = useState([]);

  const [mesesDisponiveis, setMesesDisponiveis] = useState([]);

  const [carregando, setCarregando] = useState(true);
  const [resultado, setResultado] = useState({});
  const [dataDeAtualizacao, setDataDeAtualizacao] = useState("-");

  const tipoAngel = location.state.tipo;
  const distrito = location.state.distrito;

  useEffect(() => {
    setCarregando(true);

    LogisticService.getPeriodosDiponiveisRV(tipoAngel).then((response) => {
      setMesesDisponiveis(response.data);
    });

    const buscarDadosDaRVFactory = (tipo) =>
      ({
        greenAngel: LogisticService.getResultadosAngel,
        guardianAngel: LogisticService.getResultadosGuardian,
        supplierAngel: LogisticService.getResultadosSupplier,
        hostAngel: LogisticService.getResultadosHostAngels,
        distrital: LogisticService.getResultadosDistrital,
        regional: LogisticService.getResultadosRegional,
      }[tipo]);

    const buscarDadosDaRV = buscarDadosDaRVFactory(tipoAngel);

    buscarDadosDaRV(anoReferencia, mesReferencia, nome).then((response) => {
      setResultado(response.data.data[0].results);
      const date = response.data.data[0].dtm_modified_at;
      if (date != null) {
        setDataDeAtualizacao(
          moment
            .utc(response.data.data[0].dtm_modified_at)
            .format("DD/MM/YYYY - HH:mm")
        );
      }
      setCarregando(false);
    });
  }, [nome, anoReferencia, mesReferencia]);

  useEffect(() => {
    const buscarOutrosColaboradoresFactory = (tipo) =>
      ({
        greenAngel: LogisticService.getGreenAngelsRV,
        guardianAngel: LogisticService.getGuardiansRV,
        supplierAngel: LogisticService.getSuppliersRV,
        hostAngel: LogisticService.getHostAngelsRV,
        distrital: LogisticService.getDistritaisRV,
        regional: LogisticService.getRegionaisRV,
      }[tipo]);

    const buscarOutrosColaboradores = buscarOutrosColaboradoresFactory(
      tipoAngel
    );

    buscarOutrosColaboradores(anoReferencia, mesReferencia).then((response) => {
      if (tipoAngel === "greenAngel") {
        setOutrosNomesDisponiveis(
          response.data
            .filter((obj) => obj.district === distrito)
            .map((e) => e.name)
        );
      } else {
        setOutrosNomesDisponiveis(response.data.map((e) => e.name));
      }
    });
  }, [resultado]);

  const alterarColaborador = (novoNome) => {
    setNome(novoNome);
  };

  const alterarData = (novaData) => {
    setMesReferencia(novaData.mes);
    setAnoReferencia(novaData.ano);
  };

  function retornarPilares(resultados) {
    let pilares = [];

    if (resultados) {
      Object.keys(resultados).forEach((k) => {
        if (k.includes("pilar") && resultados[k] !== null) {
          pilares.push({
            ...resultados[k],
            nome: formatarNomePilar(k),
            atingimento: (resultados[k].atingimento * 100).toFixed(1),
            limite: (
              100 *
              resultados[k].indicadores
                .filter((i) => i.tipo !== "alavanca")
                .map((i) => parseFloat(i.peso))
                .reduce((a, b) => a + b, 0)
            ).toFixed(1),
            indicadores: resultados[k].indicadores.map((i) => ({
              ...i,
              resultado: i.detalhes.resultado,
              atingimento: (i.atingimento * 100).toFixed(1),
              peso: (i.peso * 100).toFixed(1),
            })),
          });
        }
      });
    }

    return pilares;
  }

  const AbasDeDetalhamento = () => {
    if (tipoAngel === "greenAngel") {
      return (
        <GreenAngels
          ano={anoReferencia}
          mes={mesReferencia}
          nome={nome}
          tipo={tipoAngel}
          polo={resultado ? resultado.polo : undefined}
          distrito={resultado ? resultado.distrito : undefined}
          resultado={!resultado ? null : retornarPilares(resultado)}
        />
      );
    } else if (tipoAngel === "guardianAngel") {
      return (
        <GuardianAngels
          ano={anoReferencia}
          mes={mesReferencia}
          nome={nome}
          tipo={tipoAngel}
          polo={resultado ? resultado.polo : undefined}
          distrito={resultado ? resultado.distrito : undefined}
          resultado={!resultado ? null : retornarPilares(resultado)}
        />
      );
    } else if (tipoAngel === "supplierAngel") {
      return (
        <SupplierAngels
          ano={anoReferencia}
          mes={mesReferencia}
          nome={nome}
          tipo={tipoAngel}
          polo={resultado ? resultado.polo : undefined}
          distrito={resultado ? resultado.distrito : undefined}
          resultado={!resultado ? null : retornarPilares(resultado)}
        />
      );
    } else if (tipoAngel === "hostAngel") {
      return (
        <HostAngels
          ano={anoReferencia}
          mes={mesReferencia}
          nome={nome}
          tipo={tipoAngel}
          polo={resultado ? resultado.polo : undefined}
          distrito={resultado ? resultado.distrito : undefined}
          resultado={!resultado ? null : retornarPilares(resultado)}
        />
      );
    } else if (tipoAngel === "distrital") {
      return (
        <Distritais
          ano={anoReferencia}
          mes={mesReferencia}
          nome={nome}
          tipo={tipoAngel}
          distrito={resultado ? resultado.nome : undefined}
          resultado={!resultado ? null : retornarPilares(resultado)}
        />
      );
    }
  };

  return (
    <PageLayout>
      <Box title={`Resultados`}>
        <Container title={`${nome}`}>
          <PainelDeBusca
            nome={nome}
            nomes={outrosNomesDisponiveis}
            mesesDisponiveis={mesesDisponiveis}
            alterarColaborador={alterarColaborador}
            alterarData={alterarData}
            ano={anoReferencia}
            mes={mesReferencia}
          />
          {carregando ? (
            <LoadingSpinner />
          ) : (
            <Box margin={"16px"}>
              <AtualizacaoText>{`(Data da última atualização: ${dataDeAtualizacao})`}</AtualizacaoText>
              <SumarioDeResultados
                pilares={retornarPilares(resultado)}
                resultadoFinal={(100 * resultado.resultado_final).toFixed(1)}
              />
              <AbasDeDetalhamento
                ano={anoReferencia}
                mes={mesReferencia}
                nome={nome}
                polo={resultado ? resultado.polo : undefined}
                distrito={resultado ? resultado.distrito : undefined}
                resultado={!resultado ? null : retornarPilares(resultado)}
              />
            </Box>
          )}
        </Container>
      </Box>
    </PageLayout>
  );
};

export default Dashboard;
