import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { DrawingManager, GoogleMap, InfoWindow, Polygon, LoadScript } from "@react-google-maps/api";
import { useSelector, useDispatch } from "react-redux";
import { setOldSenninhaWorkFlow } from "../../../../../features/senninha/oldSenninhaSlice";
import { hubsSelector } from "../../../../../features/senninha/hubsSlice";
import LogisticService from "../../../../../consumingApi/services/LogisticService";
import Colors from "@colors";
import mapColors from "@mapColors";
import Box from "@components/Box";
import CustomLink from "@components/CustomLink";
import SelectBox from "@components/SelectBox";
import CustomButton from "@components/CustomButton";
import { Row, Space, Tooltip, Switch } from "antd";
import {
  AngelName,
  InfoLine,
  InfoWindowContent,
} from "./styles";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  UserSwitchOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";

import * as S from "./styles";


const AreaPoligono = ({ map }) => {
  const [poligono, setPoligono] = useState(null);
  const [poligonoRef, setPoligonoRef] = useState([]);
  const [areasPoligono, setAreasPoligono] = useState([]);
  const [areasAngels, setAreasAngels] = useState([]);
  const [areasRisco, setAreasRisco] = useState([]);
  const [novoPoligono, setNovoPoligono] = useState(null);
  const [angelPoligono, setAngelPoligono] = useState(null);
  const [angelParaTrocar, setAngelParaTrocar] = useState(null);
  const [angelsDisponiveis, setAngelsDisponiveis] = useState([]);
  const [poligonoSendoEditado, setPoligonoSendoEditado] = useState(null);
  const [infoWindowAtivo, setInfoWindowAtivo] = useState(false);
  const [poligonoParaTrocar, setPoligonoParaTrocar] = useState(null);
  const [poligonoParaDeletar, setPoligonoParaDeletar] = useState(null);
  const [poligonoClicado, setPoligonoClicado] = useState({
    'area': {},
    'ref': null,
    'index': 0
  });

  const [angelPoligonoSelecionado, setAngelPoligonoSelecionado] = useState(null);
  const [step, setStep] = useState('poligonos');

  const [cores, setCores] = useState({});
  const [indexCorCurr, setIndexCorCurr] = useState(1);

  const containerRef = useRef(null);
  const reactRootRef = useRef(null);
  const infoWindowRef = useRef(new google.maps.InfoWindow());

  const dispatch = useDispatch()

  const { selectedDistrictInfos, selectedHubInfos } = useSelector(hubsSelector);

  const cancelAll = () => {
    setPoligonoSendoEditado(null);
    setPoligonoParaDeletar(null);
    setPoligonoParaTrocar(null);
    setAngelParaTrocar(null);
  };

  const opcoesExtra = [
    { name: "Área de Risco" },
    { name: "Permitido Moto" },
    { name: "Proibido Carro" },
  ];

  const noAngel = [
    `Não alocadas - Vencidas`,
    `Não alocadas - Vencem hoje`,
    `Não alocadas - Vencimento futuro`,
    `COORDENADAS ZERADAS`,
    `Baixadas`,
    `Área de Risco`,
    `Permitido Moto`,
    `Proibido Carro`,
  ];

  const escolheCor = (id) => {
    const length = Object.keys(mapColors).length;
    let index = id;
    while (index > length) {
      index -= length;
    }
    return mapColors[index].replace("#", "%23");
  };

  const renderAreas = (data) => {
    let legendaData = [];
    let cor;
    let riscoList = [];
    let angelsList = [];
    let indexCor = indexCorCurr;
    let icon;

    // Função auxiliar para verificar se os bounds são válidos
    const areBoundsValid = (bounds) => {
      return bounds && bounds.length > 0; // Verifique se os bounds não estão vazios ou indefinidos
    };

    const areas = data.reduce((acc, item, index) => {
      if (!areBoundsValid(item.bounds)) {
        return acc; // Pula este item se os bounds não são válidos
      }

      if (item.name in cores) {
        cor = cores[item.name];
      } else {
        if (item.name === noAngel[4]) {
          cor = Colors.risk.replace("#", "%23");
        } else if (item.name === noAngel[5]) {
          cor = Colors.allowed.replace("#", "%23");
        } else if (item.name === noAngel[6]) {
          cor = Colors.prohibited.replace("#", "%23");
        } else {
          cor = escolheCor(indexCor++);
        }
        cores[item.name] = cor;
      }
      icon = { pin: false, cor: cor };
      const indexLegenda = legendaData.findIndex((el) => el.nome === item.name);
      if (indexLegenda === -1) {
        legendaData.push({ icon, nome: item.name });
      }
      const linha = { id: item.id, bounds: item.bounds, nome: item.name, cor };
      if (noAngel.includes(item.name)) {
        riscoList.push(linha);
      } else {
        angelsList.push(linha);
      }
      acc.push(linha);
      return acc;
    }, []);

    legendaData.sort((x, y) => {
      return noAngel.includes(x.nome) ? -1 : noAngel.includes(y.nome) ? 1 : 0;
    });

    setAreasPoligono(areas);
    setAreasAngels(angelsList);
    setAreasRisco(riscoList)
    // setLegenda(legendaData);
  };

  useEffect(() => {
    LogisticService.getAreas(selectedDistrictInfos.value).then((result) => {
      renderAreas(result.data);
    });
  }, [selectedDistrictInfos.value]);

  useEffect(() => {
    LogisticService.getAngelsDisponiveis(selectedHubInfos.children).then((angels) => {
      let list = [];
      angels.data.map((angel, index) => {
        list.push({
          id: index,
          name: angel.nome,
          value: angel.email,
          document_number: angel.document_number,
        });
      });
      setAngelsDisponiveis([...list]);
    });
  }, []);

  const PoligonosDesenhoVisibilidade = () => {
    return (
      <>
        {map && areasPoligono.map((area, index) => (
          <Polygon
            options={{
              fillColor: "#696969",
              strokeColor: "#696969",
              fillOpacity: 0.4,
              strokeWeight: 2,
            }}
            visible={true}
            key={index}
            path={area["bounds"]}
            editable={false}
            map={map}
          />
        ))}
      </>
    );
  };

  const getPolygonByRef = (index) => {
    onPolygonComplete(poligonoRef[index]);
  }
  const createPolygon = (path) => {
    const poligono = new window.google.maps.Polygon({
      path: path,
      fillOpacity: 0.5,
      strokeWeight: 2,
      editable: true,
      clickable: true,
    });

    return poligono;
  };

  const onPolygonComplete = (poligono, destroy = false) => {
    setPoligono(createPolygon(poligono.getPath())); // o polígono que vai aparecer na view do Mapa
    if (destroy) {
      poligono.setMap(null);
    }
    setNovoPoligono(getPolygonCoords(poligono)); // as coordenadas do polígono que vai pro post, no index do Senninha
  };

  const getPolygonCoords = (poligono) => {
    if (poligono !== null) {
      let len = poligono.getPath().getLength();
      let coordenadas = [];
      for (let i = 0; i < len; i++) {
        const coordenada = poligono
          .getPath()
          .getAt(i)
          .toUrlValue(10)
          .split(",");
        coordenadas.push({
          lat: parseFloat(coordenada[0]),
          lng: parseFloat(coordenada[1]),
        });
      }
      return coordenadas;
    }
  };

  const salvarPoligono = () => {
    LogisticService.postArea({
      nome: angelPoligono,
      distrito: selectedDistrictInfos.value,
      coordenadas: novoPoligono,
    }).then((result) => {
      if (result.status === 200) {
        setInfoWindowAtivo(true)
        cancelAll()
      }
      setStep('poligonos')
      LogisticService.getAreas(selectedDistrictInfos.value).then((result) => {
        renderAreas(result.data);
      });
    });
  };

  const editarPoligono = (id) => {
    LogisticService.postArea({
      id: id,
      nome: angelPoligono || angelParaTrocar,
      distrito: selectedDistrictInfos.value,
      coordenadas: novoPoligono,
    }).then((result) => {
      if (result.status === 200) {
        setInfoWindowAtivo(true)
        cancelAll()
      }
      LogisticService.getAreas(selectedDistrictInfos.value).then((result) => {
        renderAreas(result.data);
      });
    });
  };

  const deletarPoligono = (id) => {
    LogisticService.deleteArea(id).then((result) => {
      if (result.status === 200) {
        setInfoWindowAtivo(true)
        cancelAll()
      }
      LogisticService.getAreas(selectedDistrictInfos.value).then((result) => {
        renderAreas(result.data);
      });
    });
  };

  const onChangeAngelTroca = (value) => {
    setAngelPoligono(value);
    setAngelParaTrocar(value);
  };

  const onChangeAngelPoligono = (value) => {
    setAngelPoligono(value);
    setAngelPoligonoSelecionado(value);
  };

  const CustomMarker = () => {
    return (
      <InfoWindowContent>
        <div style={{ display: 'flex', gap: '8px', cursor: 'pointer' }} onClick={() => {
          infoWindowRef.current.close();
          setInfoWindowAtivo(false);
        }}>
          <AngelName>{poligonoClicado.area.nome}</AngelName>
          <GingaIcon name="close" color="black" />
        </div>
        <Box verticalAlign={"center"}>
          {poligonoSendoEditado ? (
            <Space>
              <Tooltip title="Salvar" placement="bottom">
                <SaveOutlined
                  style={{ fontSize: 22, color: Colors.deepBlue }}
                  onClick={() => {
                    editarPoligono(poligonoSendoEditado);
                  }}
                />
              </Tooltip>
              <Tooltip title="Cancelar" placement="bottom">
                <CloseCircleOutlined
                  style={{ fontSize: 22, color: Colors.deepBlue }}
                  onClick={() => {
                    cancelAll();
                  }}
                />
              </Tooltip>
            </Space>
          ) : poligonoParaDeletar ? (
            <Space>
              <Tooltip title="Confirmar" placement="bottom">
                <CheckCircleOutlined
                  style={{ fontSize: 22, color: Colors.deepBlue }}
                  onClick={() => {
                    deletarPoligono(poligonoParaDeletar);
                  }}
                />
              </Tooltip>
              <Tooltip title="Cancelar" placement="bottom">
                <CloseCircleOutlined
                  style={{ fontSize: 22, color: Colors.deepBlue }}
                  onClick={() => {
                    cancelAll();
                  }}
                />
              </Tooltip>
            </Space>
          ) : poligonoParaTrocar ? (
            <>
              <InfoLine>
                Selecione um novo Angel ou área para este polígono:
              </InfoLine>
              <SelectBox
                margin={"8px 0"}
                placeholder="Selecionar"
                options={[...opcoesExtra, ...angelsDisponiveis]}
                onChange={onChangeAngelTroca}
                value={angelParaTrocar || angelPoligono}
                valueIsName
              />
              <Space>
                <Tooltip title="Confirmar" placement="bottom">
                  <CheckCircleOutlined
                    style={{ fontSize: 22, color: Colors.deepBlue }}
                    onClick={() => {
                      editarPoligono(poligonoParaTrocar);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Cancelar" placement="bottom">
                  <CloseCircleOutlined
                    style={{ fontSize: 22, color: Colors.deepBlue }}
                    onClick={() => {
                      cancelAll();
                    }}
                  />
                </Tooltip>
              </Space>
            </>
          ) : (
            <Space>
              <Tooltip title="Trocar Angel" placement="bottom">
                <UserSwitchOutlined
                  style={{ fontSize: 22, color: Colors.deepBlue }}
                  onClick={() => {
                    setPoligonoParaTrocar(poligonoClicado.area.id);
                  }}
                />
              </Tooltip>
              <Tooltip title="Editar" placement="bottom">
                <EditOutlined
                  style={{ fontSize: 22, color: Colors.deepBlue }}
                  onClick={() => {
                    setPoligonoSendoEditado(poligonoClicado.area.id);
                    // getPolygonByRef(poligonoClicado.index);
                  }}
                />
              </Tooltip>
              <Tooltip title="Apagar" placement="bottom">
                <DeleteOutlined
                  style={{ fontSize: 22, color: Colors.deepBlue }}
                  onClick={() => {
                    setPoligonoParaDeletar(poligonoClicado.area.id);
                  }}
                />
              </Tooltip>
            </Space>
          )}
        </Box>
      </InfoWindowContent>
    )
  }

  useEffect(() => {
    if (!containerRef.current) {
      containerRef.current = document.createElement("div");
      reactRootRef.current = createRoot(containerRef.current);
    }

    reactRootRef.current.render(<CustomMarker />);

    if (poligonoClicado?.ref) {
      google.maps.event.addListener(poligonoClicado.ref, 'click', (event) => {
        infoWindowRef.current.setContent(containerRef.current);
        infoWindowRef.current.setPosition(event.latLng);
        infoWindowRef.current.open(map);
      });
    }

  }, [poligonoClicado, poligonoParaDeletar, poligonoSendoEditado,
    poligonoParaTrocar, map, angelPoligono, poligono]);

  useEffect(() => {
    let mouseMoveListener;

    if (poligonoClicado?.ref && infoWindowAtivo) {
      mouseMoveListener = google.maps.event.addListener(poligonoClicado.ref, 'mousemove', () => {
        infoWindowRef.current.close();
        setInfoWindowAtivo(false);
      });
    }

    return () => {
      if (mouseMoveListener) {
        google.maps.event.removeListener(mouseMoveListener);
      }
    };
  }, [poligonoClicado, infoWindowAtivo, poligono, map]);

  const renderPoligonos = () => {
    return (
      <>
        {map && areasPoligono.map((area, index) => (
          <Polygon
            options={{
              fillColor: area["cor"].replace("%23", "#"),
              strokeColor: area["cor"].replace("%23", "#"),
              fillOpacity: 0.5,
              strokeWeight: 2,
            }}
            key={index}
            path={area["bounds"]}
            editable={poligonoSendoEditado === area["id"]}
            onLoad={(poligono) => {
              return (poligonoRef[index] = poligono)
            }}
            map={map}
            onMouseUp={poligono ? () => getPolygonByRef(index) : () => { }}
            onMouseDown={() => {
              getPolygonByRef(index)
              setPoligonoClicado({
                'area': area,
                'ref': poligonoRef[index],
                'index': index
              })
            }}
          />
        ))}
      </>
    );
  };

  useEffect(() => {
    setPoligono(null) // Limpar o poligono desenhado caso a pessoa volte a tela
  }, [step]);

  useEffect(() => {
    var drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
    });
    if (step === 'desenhar') {

      drawingManager.setMap(map)
      google.maps.event.addListener(drawingManager, 'polygoncomplete', (poligono) => {
        onPolygonComplete(poligono, true);
        drawingManager.setMap(null)
      });
    } else {
      drawingManager.setMap(null)
    }
  }, [map, step])

  const renderAreaDesenho = () => {
    return (
      <>
        {poligono && (
          <>
            <Polygon
              path={poligono.getPath()}
              clickable={true}
              editable={true}
              options={{
                fillOpacity: poligono.fillOpacity,
                strokeWeight: poligono.strokeWeight,
              }}
              onLoad={(poligono) => (poligonoRef[0] = poligono)}
              map={map}
            />
          </>
        )}
        <PoligonosDesenhoVisibilidade />
      </>
    );
  };

  const toggleAreasAngels = () => {
    let array = areasPoligono;
    areasAngels.map((item) => {
      const position = array.indexOf(item);
      if (position === -1) {
        array.push(item);
      } else {
        array.splice(position, 1);
      }
    });
    setAreasPoligono([...array]);
  };

  const toggleAreasRisco = () => {
    let array = areasPoligono;
    areasRisco.map((item) => {
      const position = array.indexOf(item);
      if (position === -1) {
        array.push(item);
      } else {
        array.splice(position, 1);
      }
    });
    setAreasPoligono([...array]);
  };

  return (
    <>
      {
        step === 'poligonos' ? (

          <S.PoligonoContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <GingaIcon
                name="chevron-left"
                color="#303742"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(setOldSenninhaWorkFlow(''))
                }}
              />
              <span style={{ fontWeight: 600 }}>
                POLÍGONOS
              </span>
            </div>
            <Box verticalAlign={"start"}>
              <S.Info>
                Selecione no mapa o polígono que deseja editar ou clique no botão
                abaixo para adicionar um novo polígono:
              </S.Info>
              <S.Column>
                <S.ColumnTitle>Visualização:</S.ColumnTitle>
                <Box margin={"0px"}>
                  <Row width={"160px"} margin={"0 0 4px"}>
                    <S.Info margin={"0 8px 0 0"} size={"16px"}>
                      Áreas Angels
                    </S.Info>
                    <Switch
                      defaultChecked
                      onChange={() => toggleAreasAngels()}
                    />
                  </Row>
                  <Row width={"200px"} margin={"0"}>
                    <S.Info margin={"0 8px 0 0"} size={"16px"}>
                      Áreas de Restrição
                    </S.Info>
                    <Switch
                      defaultChecked
                      onChange={() => toggleAreasRisco()}
                    />
                  </Row>
                </Box>
              </S.Column>
              <Box margin={"0"} verticalAlign={"center"} width={"100%"}>
                <CustomButton
                  onClick={() => setStep("desenhar")}
                // disabled={props.editando}
                >
                  Desenhar <RightOutlined />
                </CustomButton>
              </Box>
            </Box>
          </S.PoligonoContainer>

        ) : (
          <S.PoligonoContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <GingaIcon
                name="chevron-left"
                color="#303742"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setStep('poligonos')
                  setNovoPoligono(null)
                }}
              />
              <span style={{ fontWeight: 600 }}>
                POLÍGONOS
              </span>
            </div>
            <Box verticalAlign={"start"}>
              <S.Info>
                Desenhe o novo polígono e selecione abaixo o angel ou tipo de
                área:
              </S.Info>
              <Box margin={"0"} verticalAlign={"center"} width={"100%"}>
                <SelectBox
                  margin={"0 0 8px"}
                  placeholder="Selecionar"
                  options={[...opcoesExtra, ...angelsDisponiveis]}
                  onChange={onChangeAngelPoligono}
                  value={angelPoligonoSelecionado || "Selecionar"}
                  valueIsName
                />
                <CustomButton
                  onClick={() => salvarPoligono()}
                  disabled={
                    angelPoligonoSelecionado === null ||
                    novoPoligono === null
                  }
                >
                  <CheckCircleOutlined /> Confirmar
                </CustomButton>
                <CustomLink danger onClick={() => {
                  setNovoPoligono(null)
                  setStep("poligonos")
                }}>
                  Cancelar
                </CustomLink>
              </Box>
            </Box>
          </S.PoligonoContainer>
        )
      }


      {areasPoligono && map && step === 'poligonos' ? (
        renderPoligonos()
      ) : (
        renderAreaDesenho()
      )}
    </>
  );
};

export default AreaPoligono;
