import React, { useEffect, useState } from "react";
import {
  Button,
  Select,
  Drawer,
  Dialog,
} from "@stonelog/stonelog-design-system";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import RelocateRoutes from "./RelocateRoutes";
import SwapRoutes from "./SwapRoutes";
import { useDispatch, useSelector } from "react-redux";
import {
  applyFilter,
  pinsSelector,
  resetSelectedRelocatePins,
  setMapWorkflow,
} from "../../../../../../../../../features/senninha/pinsSlice";
import { hubsSelector } from "../../../../../../../../../features/senninha/hubsSlice";
import { getAngels } from "../../../../../../../../../features/senninha/angelsSlice";
import {
  postManageAllRoutes,
  resetRoutesManagementStates,
  resetRoutesManagementStatus,
  resetUnassociatedPinAnyData,
  routesManagementSelector,
  setUnassociatedPinAnyData,
} from "../../../../../../../../../features/senninha/routesManagementSlice";
import warning from "@assets/icons/Warning.svg";

import * as S from "./styles";

// TODO: remover consoles.log
const RoutePlanning = ({ onClose, showNotification }) => {
  const dispatch = useDispatch();

  const { selectedHubInfos } = useSelector(hubsSelector);

  const { pins, filters, selectedRelocatePins, mapWorkflow } = useSelector(
    pinsSelector
  );

  const {
    unassociatedPinAnyData,
    manageAllRoutesProgress,
    statusManageAllRoutes,
  } = useSelector(routesManagementSelector);

  const [openCloseModal, setOpenCloseModal] = useState(false);
  const [newWorkflow, setNewWorkFlow] = useState(true);
  const [selectedOption, setSelectedOption] = useState("realocar");
  const [checkedList, setCheckedList] = useState([]);
  const [partialErrorModalOpen, setPartialErrorModalOpen] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [progressTotal, setProgressTotal] = useState(0);

  const loading = statusManageAllRoutes === "pending";
  const workflowRoutes = {
    realocar: (
      <RelocateRoutes
        checkedList={checkedList}
        setCheckedList={setCheckedList}
        setNewWorkFlow={setNewWorkFlow}
        setRoutes={setRoutes}
      />
    ),
    trocar: (
      <SwapRoutes setNewWorkFlow={setNewWorkFlow} setRoutes={setRoutes} />
    ),
    desalocar: (
      <RelocateRoutes
        checkedList={checkedList}
        setCheckedList={setCheckedList}
        setNewWorkFlow={setNewWorkFlow}
        setRoutes={setRoutes}
        isDeallocate
      />
    ),
  };

  const resetData = () => {
    setCheckedList([]);
    dispatch(resetUnassociatedPinAnyData());
    dispatch(setMapWorkflow("routesDrawer-relocation"));
  };

  const RowInfo = ({ type, rowData }) => {
    const aux = {
      relocation: {
        title: "Realocação",
        description: `${rowData?.angel?.nome}, ${rowData?.pins?.length} Pins, ${rowData?.ossAmount} OS`,
        iconBg: "#287DF31A",
        iconName: "solid-avatar",
        iconColor: "#287DF3",
      },
      exchange: {
        title: "Troca de rotas",
        description: `${rowData?.angel1?.nome} e ${rowData?.angel2?.nome}`,
        iconBg: "#00A8681A",
        iconName: "solid-handshake",
        iconColor: "#00A868",
      },
      deallocation: {
        title: "Desalocação",
        description: `${rowData?.pins?.length} Pins, ${rowData?.ossAmount} OS`,
        iconBg: "#F384281A",
        iconName: "solid-avatar-delete",
        iconColor: "#F38428",
      },
    };

    return (
      <>
        <Drawer.RowPrefix
          style={{
            backgroundColor: aux[type].iconBg,
            padding: 8,
            borderRadius: "50%",
            height: 32,
          }}
        >
          <GingaIcon
            name={aux[type].iconName}
            color={aux[type].iconColor}
            size={16}
          />
        </Drawer.RowPrefix>
        <Drawer.RowText
          text={aux[type].title}
          description={aux[type].description}
        />
      </>
    );
  };

  const areAllAssociatedPinsSelected = () => {
    const selectedPinsMap = selectedRelocatePins.reduce(
      (data, pin) => ({
        ...data,
        [pin.id]: pin,
      }),
      {}
    );

    return selectedRelocatePins.every(
      (pin) =>
        pin.servico !== "COLETA" ||
        pin.associatedPinsIDs.every(
          (id) =>
            selectedPinsMap[id] && selectedPinsMap[id].servico === "ENTREGA"
        )
    );
  };

  const handleDeleteRoute = (i) =>
    setRoutes([...routes.slice(0, i), ...routes.slice(i + 1)]);

  const getOSsCount = (pins) =>
    pins.length
      ? pins.reduce((sum, item) => {
          if (item?.servico?.toLowerCase() === "coleta") return sum; // TODO: aq vai vir OS dps?
          return sum + item?.oss?.length;
        }, 0)
      : 0;

  const getOSsCountFromPayload = (payload) =>
    payload.reduce((sum, item) => {
      const pins =
        item.pins ??
        [...(item.pins1 || [])].concat([...(item.pins2 || [])]) ??
        [];

      const ossCount = pins.reduce((sum2, item2) => {
        if (item2?.servico?.toLowerCase() === "coleta") return sum2; // TODO: aq vai vir OS dps?
        return sum2 + item2.oss.length;
      }, 0);
      return sum + ossCount;
    }, 0);

  const handleManageRoutes = () => {
    setProgressTotal(getOSsCountFromPayload(routes));
    const payload = [].concat(
      ...routes.map((item) =>
        item.type === "exchange"
          ? [
              {
                type: item.type,
                angel: item.angel1,
                pins: item.pins2,
                polo: item.polo,
                campanha: item.campanha,
              },
              {
                type: item.type,
                angel: item.angel2,
                pins: item.pins1,
                polo: item.polo,
                campanha: item.campanha,
              },
            ]
          : [{ ...item, campanha: true }]
      )
    );

    dispatch(
      postManageAllRoutes({
        data: payload,
      })
    );
  };

  const handleManageRoutesRetry = () => {
    const payload = manageAllRoutesProgress.retryPayload;
    setProgressTotal(getOSsCountFromPayload(payload));
    dispatch(resetRoutesManagementStates());
    dispatch(
      postManageAllRoutes({
        data: payload,
      })
    );
    setPartialErrorModalOpen(false);
  };

  useEffect(() => {
    dispatch(getAngels(selectedHubInfos.children));
  }, []);

  useEffect(() => {
    dispatch(resetRoutesManagementStates());
    dispatch(resetSelectedRelocatePins());

    return () => {
      dispatch(setMapWorkflow("infoDrawer"));
      dispatch(resetSelectedRelocatePins());
      dispatch(resetRoutesManagementStates());
    };
  }, []);

  useEffect(() => {
    dispatch(resetSelectedRelocatePins());
    setCheckedList([]);
    dispatch(resetUnassociatedPinAnyData());
  }, [selectedOption, routes]);

  useEffect(() => {
    if (selectedOption === "realocar")
      dispatch(setMapWorkflow("routesDrawer-relocation"));
    if (selectedOption === "trocar")
      dispatch(setMapWorkflow("routesDrawer-exchange"));
    else setMapWorkflow("routesDrawer");

    resetData();
  }, [newWorkflow, selectedOption]);

  useEffect(() => {
    if (selectedRelocatePins.length) {
      const selectedCollectPins = pins.filter(
        (item) =>
          item.associatedPinsIDs &&
          selectedRelocatePins.find((item2) => item2.id === item.id)
      );

      const collectPinsUnallocated = selectedCollectPins.filter(
        ({ associatedPinsIDs }) =>
          !selectedRelocatePins.find((item) =>
            associatedPinsIDs.includes(item.id)
          )
      );

      if (collectPinsUnallocated.length) {
        dispatch(
          setUnassociatedPinAnyData({
            unassociatedPin: collectPinsUnallocated[0],
            deliveriesPins2BeAssociated: pins.filter((item) =>
              collectPinsUnallocated[0]?.associatedPinsIDs.includes(item.id)
            ),
          })
        );
      }
      const selectedIds = unassociatedPinAnyData.deliveriesPins2BeAssociated?.reduce(
        (data, item) => {
          const pinSelected = selectedRelocatePins.filter(
            (item2) => item2.id === item.id
          );
          if (pinSelected.length) return [...data, item.id];
          return data;
        },
        []
      );
      if (selectedIds) setCheckedList(selectedIds);

      if (areAllAssociatedPinsSelected()) {
        resetData();
      }
    } else resetData();
  }, [selectedRelocatePins]);

  useEffect(() => {
    const lastSelectedPin =
      selectedRelocatePins[selectedRelocatePins.length - 1];
    if (unassociatedPinAnyData.unassociatedPin && lastSelectedPin) {
      const isLastPinAssociated = unassociatedPinAnyData.unassociatedPin.associatedPinsIDs.includes(
        lastSelectedPin.id
      );
      const lastPinIsNotTheUnassociated =
        isLastPinAssociated &&
        isLastPinAssociated.id !== unassociatedPinAnyData.unassociatedPin.id;

      if (
        lastSelectedPin?.tipo?.toLowerCase() !== "any" ||
        (!isLastPinAssociated && lastPinIsNotTheUnassociated)
      ) {
        resetData();
      }
    }
  }, [selectedRelocatePins]);

  useEffect(() => {
    if (statusManageAllRoutes === "fulfilled") {
      if (filters.length) dispatch(applyFilter(filters));
      dispatch(resetSelectedRelocatePins());
      dispatch(resetRoutesManagementStatus());
      resetData();
      onClose();
      showNotification("Operação realizada com sucesso!");
    }
    if (statusManageAllRoutes === "rejected") {
      setPartialErrorModalOpen(true);
      dispatch(resetRoutesManagementStatus());
    }
  }, [statusManageAllRoutes]);

  return (
    <>
      <Dialog.Root
        open={openCloseModal}
        onCancel={() => setOpenCloseModal(false)}
      >
        <Dialog.Header title="Atenção" />

        <Dialog.ContentHeader
          ilustration={<img src={warning} />}
          description="Ao cancelar, você perderá todas as informações inseridas. Tem certeza de que deseja continuar?"
        />

        <Dialog.Footer>
          <Button onClick={() => setOpenCloseModal(false)}>Voltar</Button>
          <Button
            type="negative"
            onClick={() => {
              onClose();
              setRoutes([]);
              setMapWorkflow("infoDrawer");
              setNewWorkFlow(true);
            }}
          >
            Confirmar cancelamento
          </Button>
        </Dialog.Footer>
      </Dialog.Root>

      <Dialog.Root
        open={partialErrorModalOpen}
        onCancel={() => setPartialErrorModalOpen(false)}
        centered
      >
        <Dialog.Header title="Erro" />

        <Dialog.ContentHeader
          ilustration={<img src={warning} />}
          description={`Não conseguimos realocar pois encontramos falhas em ${getOSsCount(
            manageAllRoutesProgress.errors
          )} de ${progressTotal} itens. Deseja tentar novamente?`}
        />

        <Dialog.ContentDetails
          details={manageAllRoutesProgress.errorsMessages || []}
        />

        <Dialog.Footer>
          <Button
            onClick={() => {
              setPartialErrorModalOpen(false);
              dispatch(resetRoutesManagementStates());
            }}
          >
            Cancelar
          </Button>
          <Button
            type="primary"
            loading={statusManageAllRoutes === "pending"}
            onClick={handleManageRoutesRetry}
          >
            Tentar novamente
          </Button>
        </Dialog.Footer>
      </Dialog.Root>

      <Drawer.Body>
        {loading ? (
          <>
            <Drawer.Loading
              loadingMessage={`Aguarde um momento, realocando... ${getOSsCount(
                manageAllRoutesProgress.successes.concat(
                  manageAllRoutesProgress.errors
                )
              )} de ${progressTotal} já foram concluídos.`}
            />
          </>
        ) : (
          <>
            <S.BodyTopContainer>
              <Button
                type="primary"
                size="small"
                disabled={newWorkflow}
                onClick={() => {
                  setNewWorkFlow(true);
                  setSelectedOption("realocar");
                }}
              >
                <GingaIcon size="16px" name="round-add" color="#FFF" />
                Adicionar nova função
              </Button>

              {/* <S.BodyTopRightContent> ----->>> V1

                                <Tag
                                    iconName='transport-motorcycle'
                                    text='R$380,00'
                                    type='success'
                                />
                                <p>
                                    Gaste hoje R$54,90
                                </p>
                            </S.BodyTopRightContent> */}
            </S.BodyTopContainer>
            {newWorkflow && (
              <S.BodyMiddleContainer>
                <Select
                  label="Função"
                  options={[
                    {
                      label: "Realocar OS",
                      value: "realocar",
                    },
                    {
                      label: "Trocar rotas",
                      value: "trocar",
                    },
                    {
                      label: "Desalocar OS",
                      value: "desalocar",
                    },
                  ]}
                  placeholder="Selecione uma opção"
                  defaultValue="realocar"
                  onChange={(e) => {
                    setSelectedOption(e);
                  }}
                  size="small"
                />

                {workflowRoutes[selectedOption]}
              </S.BodyMiddleContainer>
            )}

            {routes.map((item, i) => (
              <Drawer.Row key={i}>
                <RowInfo type={item.type} rowData={item} />
                <Drawer.RowActions>
                  <Button
                    solid
                    radius="99px"
                    width="36px"
                    height="36px"
                    onClick={() => handleDeleteRoute(i)}
                  >
                    <GingaIcon name="trash" color="#E6171E" size={20} />
                  </Button>
                </Drawer.RowActions>
              </Drawer.Row>
            ))}
          </>
        )}
      </Drawer.Body>

      {!loading && (
        <Drawer.Footer>
          <Button
            type="primary"
            width="100%"
            height="52px"
            size="large"
            onClick={handleManageRoutes}
            disabled={!routes.length}
          >
            Finalizar
          </Button>
          <Button
            width="100%"
            height="52px"
            size="large"
            onClick={() => {
              setOpenCloseModal(true);
            }}
          >
            Cancelar
          </Button>
        </Drawer.Footer>
      )}
    </>
  );
};

export default RoutePlanning;
