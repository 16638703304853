import Icon, { LoadingOutlined } from "@ant-design/icons";
import { Card, Statistic } from "antd";
import React from "react";

const SumarioDeResultados = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
      }}
    >
      {Object.keys(props.pilares).map((pilar) => {
        return (
          <AtingimentoDoPilar
            key={Math.random()}
            atingimento={props.pilares[pilar].atingimento}
            limite={props.pilares[pilar].limite}
            nomeDoPilar={props.pilares[pilar].nome}
          />
        );
      })}
      <AtingimentoDoPilar
        atingimento={props.resultadoFinal}
        limite={100.0}
        nomeDoPilar="Atingimento Total"
      />
    </div>
  );
};

const AtingimentoDoPilar = (props) => {
  const loading = <LoadingOutlined spin />;
  const { icon, atingimento, limite, nomeDoPilar } = props;
  return (
    <Card style={{ minWidth: 180, margin: "18px" }}>
      <Statistic
        title={<h3>{nomeDoPilar}</h3>}
        style={{ textAlign: "center" }}
        value={!isNaN(atingimento) ? atingimento + "/" + limite : " "}
        prefix={
          <Icon
            type={icon || "none"}
            theme="twoTone"
            // twoToneColor="silver"
            style={{ verticalAlign: "middle" }}
          />
        }
        suffix={isNaN(atingimento) ? loading : ""}
      />
    </Card>
  );
};

export { AtingimentoDoPilar, SumarioDeResultados };
