import React, { useEffect, useState } from "react";
import { Button, Drawer, Tag } from "@stonelog/stonelog-design-system";
import { GingaIcon } from "@stonelog/stonelog-ginga-icons";
import { useSelector, useDispatch } from "react-redux";
import { pinsSelector, setBouncePin, setCenter, setZoom } from "../../../../../../../features/senninha/pinsSlice";
import { hubsSelector } from "../../../../../../../features/senninha/hubsSlice";
import { toCapitalizeFirstLetterOfEachWord } from "../../../../../../../consumingApi/services/helper";
import machineError from "@assets/imgs/machine-error.svg";
import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import * as S from "./styles";


const InfoDrawer = ({
    open,
    onClose,
}) => {
    const [openIndex, setOpenIndex] = useState(null);

    const colorsAndText = {
        "instalação": { type: "success" },
        "desinstalação": { type: "error" },
        troca: { type: "info" },
        campanha: { type: "default" },
        coleta: { type: "info" },
        entrega: { type: "success" },
        evento: { type: "hint" }
    };

    const {
        pins,
        serviceDetails,
        serviceDetailsStatus,
    } = useSelector(pinsSelector);

    const {
        selectedHubInfos,
    } = useSelector(hubsSelector);

    const dispatch = useDispatch()

    const isServiceAble2ShowDetails = (servico) => 
        ['COLETA', 'ENTREGA'].includes(serviceDetails.pinDetails.servico) 
        && serviceDetails.pinIdentifiers.servico?.toLowerCase() !== servico?.toLowerCase()

    const openCard = (index, servico) => {
        if (!isServiceAble2ShowDetails(servico))
            setOpenIndex(index === openIndex ? null : index);
    };

    const handleSeeOnMapClick = (lat, lng) => {
        dispatch(setCenter({
            center: {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
            }
        }))
        dispatch(setZoom(20))
    }

    const formatDate = (dateStr) => {
        if (!dateStr) return '';
        const date = parseISO(dateStr);
        return format(date, "d 'de' MMM 'de' y", { locale: ptBR });
    }

    useEffect(() => {
        if (serviceDetails.pinDetails && serviceDetails.pinIdentifiers) {
            const index = serviceDetails.pinDetails.oss.findIndex((item) => 
                item.id_os === serviceDetails.pinIdentifiers.id_os 
                && (serviceDetails.pinDetails.servico === serviceDetails.pinIdentifiers.servico 
                    || item.servico === serviceDetails.pinIdentifiers.servico
                )
            ) 

            if (index !== -1) setOpenIndex(index)
            else setOpenIndex(null)
        }
    }, [serviceDetails, open])

    return (
        <Drawer.Root
            placement='right'
            open={open}
            onClose={() => onClose()}
            mask={false}
            title={
                <Drawer.Header
                    title="Detalhes"
                    date={new Date()}
                />
            }
        >
            <Drawer.Body>
                {serviceDetailsStatus === 'pending' ? (
                    <Drawer.Loading />
                ) : (
                    <div>
                        {serviceDetailsStatus === 'fulfilled' && serviceDetails.pinDetails && (
                            <S.AllBodyContainer>
                                <S.BodyTopContainer>
                                    <S.BodyTopContent>
                                        <p>
                                            Alocado para
                                        </p>
                                        <span>
                                            {toCapitalizeFirstLetterOfEachWord(serviceDetails.pinDetails?.angel)}
                                        </span>
                                    </S.BodyTopContent>
                                    <S.BodyTopContent>
                                        <p>
                                            {serviceDetails.pinDetails.tipo?.toLowerCase() === 'adquirencia' ? `Stonecode` : `Código de rastreio`}
                                        </p>
                                        <span>
                                            {serviceDetails.pinDetails?.id_atendimento}
                                        </span>
                                    </S.BodyTopContent>
                                    <S.BodyTopContent>
                                        <p>
                                            {serviceDetails.pinDetails.tipo?.toLowerCase() === 'adquirencia' ? `Quantidade de OS` : `Quantidade de ID Delivery`}
                                        </p>
                                        <span>
                                            {serviceDetails.pinDetails?.volume_oss}
                                        </span>
                                    </S.BodyTopContent>
                                    <S.BodyTopContent>
                                        <p>
                                            Cliente
                                        </p>
                                        <span>
                                            {toCapitalizeFirstLetterOfEachWord(serviceDetails.pinDetails?.cliente)}
                                        </span>
                                    </S.BodyTopContent>
                                    <S.BodyTopContent>
                                        <p>
                                            Endereço
                                        </p>
                                        <span>
                                            {serviceDetails.pinDetails?.endereco?.replaceAll('"', '')}
                                        </span>
                                    </S.BodyTopContent>
                                    <S.BodyTopContent>

                                        <p>
                                            Polo
                                        </p>
                                        <span>
                                            {toCapitalizeFirstLetterOfEachWord(selectedHubInfos.children)}
                                        </span>
                                    </S.BodyTopContent>
                                </S.BodyTopContainer>
                                {serviceDetails.pinDetails?.oss.map((os, idx) => (
                                    <S.BodyMiddleContainer
                                        key={idx}
                                    >
                                        <S.BodyMiddleHeader
                                            onClick={() => openCard(idx, os.servico)}
                                            style={{ cursor: isServiceAble2ShowDetails(os.servico) ? 'auto' : 'pointer' }}
                                        >
                                            <div>
                                                <p>
                                                    {serviceDetails.pinDetails.tipo?.toLowerCase() === 'adquirencia' ? `Nº da OS` : `ID Delivery`}

                                                </p>
                                                <span>{os?.id_os}</span>
                                            </div>

                                            <S.BodyMiddleRightHeader>
                                                {os?.servico &&
                                                    <Tag
                                                        text={toCapitalizeFirstLetterOfEachWord(os.servico)}
                                                        type={colorsAndText[os.servico?.toLowerCase()]?.type}
                                                    />
                                                }
                                                {isServiceAble2ShowDetails(os.servico) ?
                                                (
                                                    <Button
                                                        width='100%'
                                                        size='small'
                                                        solid
                                                        onClick={() => {
                                                            const correctPin = pins.find((item) => 
                                                                item.servico !== serviceDetails.pinIdentifiers.servico 
                                                                && item.oss.find((item2) => item2.id_os === serviceDetails.pinIdentifiers.id_os
                                                                    && item.id?.toString() !== serviceDetails.pinDetails?.id?.toString())
                                                                )

                                                            if (correctPin) {
                                                                dispatch(setBouncePin(correctPin.id))
                                                                handleSeeOnMapClick(correctPin.lat, correctPin.lng)
                                                            }
                                                        }}
                                                    >
                                                        Mostrar no mapa
                                                    </Button>
                                                ) : (
                                                    <GingaIcon
                                                        name="chevron-down"
                                                        color="#A6AEBA"
                                                        size="24px"
                                                        style={{
                                                            transition: "all 0.5s ease",
                                                            transform: `rotate(${openIndex === idx ? "0.5turn" : "1turn"})`,
                                                        }}
                                                    />
                                                )
                                                }
                                            </S.BodyMiddleRightHeader>
                                        </S.BodyMiddleHeader>
                                        <S.BodyMiddleContent open={openIndex === idx}>
                                            <div>
                                                <p>
                                                    Data limite cliente
                                                </p>
                                                <span>{formatDate(os?.customer_deadline_date)}</span>
                                            </div>
                                            <div>
                                                <p>
                                                    Data limite
                                                </p>
                                                <span>{formatDate(os?.deadline_date)}</span>
                                            </div>
                                            <div>
                                                <p>
                                                    Contratante
                                                </p>
                                                <span>{toCapitalizeFirstLetterOfEachWord(serviceDetails.pinDetails?.contratante)}</span>
                                            </div>
                                            <div>
                                                <p>
                                                    Horário de funcionamento
                                                </p>
                                                <span>{os?.horario_funcionamento}</span>
                                            </div>
                                            {
                                                os?.pacote && (
                                                    <div>
                                                        <p>
                                                            Quantidade de pacote
                                                        </p>
                                                        <span>{os.pacote}</span>
                                                    </div>
                                                )
                                            }
                                            {serviceDetails.pinDetails.outro_polo &&
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div>
                                                        <p>
                                                            {serviceDetails.pinDetails.tipo?.toLowerCase() === 'adquirencia' ? `Nº da OS` : `ID Delivery`}

                                                        </p>
                                                        <span>{os?.id_os}</span>
                                                    </div>
                                                    <Tag
                                                        text="Coleta em outro polo"
                                                        type="warning"
                                                    />
                                                </div>
                                            }
                                        </S.BodyMiddleContent>
                                    </S.BodyMiddleContainer>
                                ))}
                            </S.AllBodyContainer>
                        )} 
                        
                        {serviceDetailsStatus === 'rejected' && (
                            <Drawer.Response
                                element={ <img src={machineError} />}
                                message="Houve um erro!"
                            />
                        )}
                    </div>
                )}

            </Drawer.Body>
            <Drawer.Footer>
                <Button
                    width="100%"
                    height="52px"
                    size="large"
                    onClick={() => onClose()}
                >
                    Fechar
                </Button>
            </Drawer.Footer>
        </Drawer.Root>
    )
};

export default InfoDrawer;
